import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { styled } from '@mui/system';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
	top: 64,
});

class BlogRollTemplate extends React.Component {
	render() {
		const { data } = this.props;
		const { edges: posts } = data.allMarkdownRemark;

		return (
			<Section
				className='columns is-multiline section'
				// sx={{ boxShadow: 12 }}
				// mx={8}
				// mt={2}
				// borderRadius={2}
				// elevation={3}
			>
				<Grid container spacing={4} sx={{ pb: 4 }}>
					{posts &&
						posts.map(({ node: post }) => (
							<Grid item xs={12} md={4}>
								<Card>
									<CardContent className='is-parent column is-6' key={post.id}>
										<article
											className={`blog-list-item tile is-child box notification ${
												post.frontmatter.featuredpost ? 'is-featured' : ''
											}`}
										>
											<header>
												{post.frontmatter.featuredimage ? (
													<div className='featured-thumbnail'>
														<PreviewCompatibleImage
															imageInfo={{
																image: post.frontmatter.featuredimage,
																alt: `featured image thumbnail for post ${post.frontmatter.title}`,
																width:
																	post.frontmatter.featuredimage.childImageSharp.gatsbyImageData
																		.width,
																height:
																	post.frontmatter.featuredimage.childImageSharp.gatsbyImageData
																		.height,
															}}
														/>
													</div>
												) : null}
												<p className='post-meta'>
													<Link
														className='title has-text-primary is-size-4'
														to={post.fields.slug}
													>
														{post.frontmatter.title}
													</Link>
													<span> &bull; </span>
													<span className='subtitle is-size-5 is-block'>
														{post.frontmatter.date}
													</span>
												</p>
											</header>
											<p>
												{post.excerpt}
												<br />
												<br />
												<Link className='button' to={post.fields.slug}>
													Keep Reading →
												</Link>
											</p>
										</article>
									</CardContent>
								</Card>
							</Grid>
						))}
				</Grid>
			</Section>
		);
	}
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 120
                        quality: 100
                        layout: CONSTRAINED
                      )

                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
